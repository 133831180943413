import React, { useState } from 'react';
// import useWindowScrollPosition from '@rehooks/window-scroll-position';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';

// HOOKS
import useWindowSize from 'hooks/useWindowSize';

// IMAGES
import logo from 'assets/images/logo.png';

// STYLES
import './styles.scss';

// CONSTANTS
// const TOPBAR_HEIGHT = 64;
const MOBILE_MAX_WIDTH = 1100;

const Nav = ({ transparent, open, toggle }) => {
  const click = () => {
    if (open) toggle();
  };
  return (
    <nav id="topbar-nav" className={classNames(/*transparent && 'transparent',*/ open && 'open')}>
      <Link to="/" onClick={click}>
        Home
      </Link>
      {/* <Link to="/institutionen" onClick={click}>Institutionen</Link>
      <Link to="/fachkraefte-drittstaaten" onClick={click}>Fachkräfte aus Drittstaaten</Link>
      <Link to="/individuelle-anmeldungen" onClick={click}>Individuelle Anmeldungen</Link> */}
      <Link to="/#ueber-uns" onClick={click}>
        Über uns
      </Link>
      <Link to="/#bereiche" onClick={click}>
        Bereiche
      </Link>
      <Link to="/#mehr-informationen" onClick={click}>
        Mehr informationen
      </Link>
      <Link to="/#kontakt" onClick={click}>
        Kontakt
      </Link>
    </nav>
  );
};

export default function Topbar({ loose }) {
  const { width: windowWidth /*, height: windowHeight */ } = useWindowSize();
  // const { y: scrollY } = useWindowScrollPosition({ throttle: 100 }); // (loose && scrollY < TOPBAR_HEIGHT) für class 'transparent' und um das logo zu switchen

  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((o) => !o);
  const transitions = useTransition(open, null, {
    duration: 300,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  // const isTransparent = (windowWidth && windowWidth >= MOBILE_MAX_WIDTH && loose && scrollY < TOPBAR_HEIGHT);
  const isTransparent = false;

  return (
    <>
      <div id="topbar" className={classNames(/*isTransparent && 'transparent'*/)}>
        <Link to="/" className="topbar-logo">
          <img src={logo} alt="Profectus" />
        </Link>

        {windowWidth && windowWidth >= MOBILE_MAX_WIDTH && <Nav transparent={isTransparent} open={open} toggle={toggle} />}

        {windowWidth && windowWidth < MOBILE_MAX_WIDTH && (
          <div id="topbar-toggle" className={classNames(open && 'is-active')} onClick={toggle}>
            <div className="topbar-toggle-box">
              <div className="topbar-toggle-inner" />
            </div>
          </div>
        )}
      </div>

      {windowWidth && windowWidth < MOBILE_MAX_WIDTH && (
        <>
          {transitions.map(({ item, key, props }) => item && <animated.div key={key} className="topbar-nav-backdrop" style={props} />)}
          <Nav transparent={isTransparent} open={open} toggle={toggle} />
        </>
      )}
    </>
  );
}
